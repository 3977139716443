import "preline";

import Swiper from "swiper";
import { EffectFade, Autoplay, Navigation, Pagination, Grid } from "swiper/modules";
// import "swiper/css/bundle";
// import 'swiper/css/effect-fade';
// import 'swiper/css/navigation';
// import 'swiper/css/Pagination';
import "swiper/swiper-bundle.min.css";
// import "swiper/modules/navigation/navigation.min.css";
// import "swiper/modules/pagination/pagination.min.css";
// import "swiper/modules/effect-fade/effect-fade.min.css";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class VideoResponser {
  constructor(selectors) {
    const videos = document.querySelectorAll(selectors);
    this.videos = Array.from(videos).map(($video) => {
      const options = {
        selector: $video,
        breakpoints: { default: { src: $video.getAttribute("src") } },
      };

      // get a list of video switching points and links to the videos themselves
      $video.querySelectorAll("[src]").forEach(
        (element) =>
          (options.breakpoints[element.getAttribute("data-mw")] = {
            src: element.getAttribute("src"),
          })
      );
      $video.innerHTML = ""; // we clean up so that there is nothing superfluous

      return { $video, options };
    });

    // run the handler and track the change in screen width
    this.videos.forEach(({ options }) => {
      this.responseVideo(options);
      this.resizer(options);
    });
  }

  /** Function runs on resize  */
  resizer(options) {
    const { selector } = options;
    window.addEventListener("resize", () => this.responseVideo(options));
  }

  /**
   * Change src value of video link to fit screen width
   *
   * @param {Object} options object with options
   */
  responseVideo(options) {
    const { selector, breakpoints } = options; // get options
    let $video = selector;
    const widthNow = $video.getAttribute("data-width-now") || null;
    const maxBreakpoint = Math.max.apply(
      Math,
      Object.keys(breakpoints)
        .filter((key) => key <= document.body.clientWidth)
        .map(Number)
    );
    const nowBreakpoint = maxBreakpoint || "default"; // choose either the maximum value, if not, then the default

    if (widthNow && widthNow == nowBreakpoint) return; // check if the video needs to be changed

    let isPlay = !$video.paused;
    $video.setAttribute("data-width-now", nowBreakpoint);
    $video.src = breakpoints[nowBreakpoint].src;

    if (isPlay) {
      let playPromise = $video.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    }
  }
}

new VideoResponser(".media-query-video");

let videoSwiper = new Swiper(".swiper-video", {
  modules: [EffectFade, Pagination],
  allowTouchMove: false,
  loop: true,
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  on: {
    init: function (i) {
      let currentSlide = this.slides[this.activeIndex];
      let video = currentSlide.querySelector("video");
      let playPromise;
      if (video) {
        playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              // Automatic playback started!
              // Show playing UI.
            })
            .catch((error) => {
              // Auto-play was prevented
              // Show paused UI.
            });
        }
      }

      if (video && !video.paused && !video.ended) {
        // 影片正在播放，等待播放完成
        video.onended = function () {
          // 影片播放完成，切換到下一個 slide
          videoSwiper.slideNext();
          console.log("1 videoSwiper.slideNext()");
        };
      }
    },
    slideChangeTransitionEnd: function (i) {
      let prevVideo = this.slides[this.previousIndex].querySelector("video");
      if (prevVideo) {
        prevVideo.pause();
        prevVideo.currentTime = 0;
        prevVideo.onended = null;
      }

      let currentSlide = this.slides[this.activeIndex];
      let video = currentSlide.querySelector("video");
      let playPromise;
      if (video) {
        playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              // Automatic playback started!
              // Show playing UI.
            })
            .catch((error) => {
              // Auto-play was prevented
              // Show paused UI.
            });
        }
      }
      if (video && !video.paused && !video.ended) {
        // 影片正在播放，等待播放完成
        video.onended = function () {
          // 影片播放完成，切換到下一個 slide
          videoSwiper.slideNext();
          console.log("2 videoSwiper.slideNext()");
        };
      }
    },
  },
});

let fadeSwiper = new Swiper(".swiper-fades", {
  modules: [EffectFade, Autoplay],
  allowTouchMove: false,
  loop: true,
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  speed: 1000,
});

let listsSwiper = new Swiper(".swiper-lists", {
  modules: [EffectFade],
  allowTouchMove: false,
  speed: 1000,
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  autoHeight: true,
});

document.querySelectorAll(".swiper-lists_nav").forEach(function (anchor, i) {
  anchor.addEventListener("click", function (e) {
    listsSwiper.slideTo(i);
  });
});

ScrollTrigger.create({
  trigger: ".scroll-mask",
  start: "bottom bottom",
  once: "true",
  toggleClass: { targets: ".scroll-mask", className: "is-active" },
  // toggleActions: "play none none none",
  // markers: true,
});

[].forEach.call(
  document.querySelectorAll(".swiper-loop"),
  function (slider, index, arr) {
    let data = slider.getAttribute("data-swiper") || "{}";
    let dataOptions = {};

    if (data) {
      dataOptions = JSON.parse(data);
    }

    slider.options = Object.assign(
      {},
      {
        modules: [Autoplay],
        loop: true,
        freeMode: true,
        allowTouchMove: false,
        // spaceBetween: 20,
        slidesPerView: 1.5,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        speed: 8000,
        breakpoints: {
          768: {
            slidesPerView: 3,
          },
        },
      },
      dataOptions
    );

    new Swiper(slider, slider.options);
  }
);

[].forEach.call(
  document.querySelectorAll(".swiper-articles"),
  function (slider, index, arr) {
    let data = slider.getAttribute("data-swiper") || "{}";
    let dataOptions = {};

    if (data) {
      dataOptions = JSON.parse(data);
    }

    slider.options = Object.assign(
      {},
      {
        modules: [Navigation, Grid],
        spaceBetween: 20,
        slidesPerView: 1,
        // allowTouchMove: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 1.5,
          },
          1024: {
            slidesPerView: 2,
          },
        },
      },
      dataOptions
    );
    new Swiper(slider, slider.options);
  }
);

gsap.utils.toArray("[data-aos^=fade]").forEach((ele) => {
  const direction = ele.dataset.aos.split("-")[1] || "null",
    delay = ele.dataset.delay || 0,
    duration = ele.dataset.duration || 0.5,
    proxy = {
      up: [0, 50],
      down: [0, -50],
      left: [50, 0],
      right: [-50, 0],
      null: [0, 0],
    };
  gsap.from(ele, {
    scrollTrigger: {
      trigger: ele,
      start: "top 90%",
      once: "true",
      // toggleActions: 'play none none reverse',
      // markers: true,
    },
    duration: parseFloat(duration),
    delay: delay,
    opacity: 0,
    xPercent: proxy[direction][0],
    yPercent: proxy[direction][1],
  });
});

document.querySelectorAll('table').forEach((table) => {
  const tableContainer = document.createElement('div');
  table.parentNode.insertBefore(tableContainer, table);
  tableContainer.classList.add('overflow-x-auto');
  const tableWrapper = document.createElement('div');
  tableWrapper.classList.add('min-w-full', 'inline-block', 'align-middle');
  const tableInner = document.createElement('div');
  tableInner.classList.add('overflow-hidden');
  tableContainer.appendChild(tableWrapper);
  tableWrapper.appendChild(tableInner);
  tableInner.appendChild(table);
});

document.querySelectorAll(".meta-menu .hover-link").forEach(function (anchor, i) {
  anchor.addEventListener("mouseenter", function (e) {
    gsap.to(".meta-menu .hover-img"+anchor.dataset.id, {
      opacity: 1,
      duration: 0.3
    });
  });
  anchor.addEventListener("mouseleave", function (e) {
    gsap.to(".meta-menu .hover-img"+anchor.dataset.id, {
      opacity: 0,
      duration: 0.3
    });
  });
});